<template>
  <div class="c-judge-editor c-judge-editor--confirm">
    <div class="c-judge-editor--confirm-body">
      <template v-for="(group, g_index) in pages">
        <div v-if="group.type !== 'group'" :key="group.group">
          <div class="c-judge-editor__confirm-table">
            <div class="c-judge-editor__confirm-table-body">
              <div class="c-judge-editor__confirm-table-label">
                {{ $t(group.type) }}
              </div>
              <div class="c-judge-editor__confirm-table-body">
              </div>
              <div v-if="group.type === 'total'" class="c-judge-editor__confirm-table-value-label-pair">
                <div class="c-judge-editor__confirm-value-label c-jugde-editor__confirm-total">
                  <span>TOT</span>
                </div>

                <scoreValue :value="group" :editing="false"/>
              </div>
            </div>
            <div class="c-judge-editor__confirm-table-button">
              <button v-if="group.type !== 'total'" v-on:click.prevent="change(g_index)"
                      class="c-button c-button--secondary"
                      :class="{'c-button--disabled': group.confirmed}">
                {{$t('panel.change')}}
              </button>
            </div>
          </div>
        </div>

        <div v-else-if="group.rosterLines === 'subElements'" :key="group.element">
          <div class="c-judge-editor__confirm-table-multiple">
            <div class="c-judge-editor__confirm-table-label c-judge-editor__confirm-table-label-multiple">
              <span class="">{{$t('score.' + group.element)}}</span>
            </div>

            <div class="c-judge-editor__confirm-table-body c-judge-editor__confirm-table-body-multiple">
              <template v-for="(element) in group.elements">
                <div class="c-judge-editor__confirm-table-value-label-pair"
                     v-for="(value, v_index) in getElementValues(element)"
                     :key="group.group + '-' + element.element + '-' + v_index">

                  <div class="c-judge-editor__confirm-table-label">
                    {{element.multiple ? (element.countLabels ? element.countLabels[value.properties.index]
                      : $t('score.'+element.element).substr(0,1).toUpperCase() + (value.properties.index+1))
                    : $t('score.'+element.element).substr(0,3).toUpperCase()}}
                  </div>

                  <div class="c-judge-editor__confirm-table-value">
                    <scoreValue :value="value" :editing="false"/>
                  </div>
                </div>

              </template>
            </div>
            <div class="c-judge-editor__confirm-table-value-label-pair">
              <div class="c-judge-editor__confirm-value-label c-jugde-editor__confirm-total">
                <span v-if="group.judgeTotal.enabled">TOT</span>
              </div>

              <scoreValue v-if="group.judgeTotal.enabled" :value="getJudgeTotalValue(group)" :editing="false"/>
            </div>


            <div class="c-judge-editor__confirm-table-button c-judge-editor__confirm-table-button-multiple">
              <button v-on:click.prevent="change(g_index)"
                      class="c-button c-button--secondary"
                      :class="{'c-button--disabled': group.confirmed}">
                {{$t('panel.change')}}
              </button>
            </div>
          </div>

        </div>

        <div v-else-if="group.rosterLines === 'separate'" :key="'r1-' + group.group">
          <div v-if="group.multiple" class="c-judge-editor__confirm-table-multiple">
            <div class="c-judge-editor__confirm-table-body c-judge-editor__confirm-table-body-multiple">
              <div class="c-judge-editor__confirm-table-label">
                <span class="">{{$t('score.' + group.group)}}</span>
              </div>
              <div class="c-judge-editor__confirm-table-value-label-pair">
                <div class="c-judge-editor__confirm-table-label">
                  {{$t('score.value')}}
                </div>
              </div>
              <div class="c-judge-editor__confirm-table-value-label-pair">
                <div v-if="group.judgeTotal.enabled" class="c-judge-editor__confirm-table-label">
                  {{$t('score.total')}}
                </div>
              </div>
            </div>
          </div>

          <div v-for="(element, e_index) in group.elements" :key="group.group + '-' + element.element"
               class="c-judge-editor__confirm-table">
            <div class="c-judge-editor__confirm-table-body">
              <div class="c-judge-editor__confirm-table-label">
                <span class="">{{$t('score.' + element.element)}}</span>
              </div>
              <div class="c-judge-editor__confirm-table-value" v-for="(value, v_index) in getElementValues(element)"
                   :key="group.group + '-' + element.element + '-' + v_index">
                <scoreValue :value="value" :editing="false"/>
              </div>
              <div class="c-judge-editor__confirm-table-group">
                <scoreValue v-if="group.judgeTotal.enabled && e_index === 0" :value="getJudgeTotalValue(group)"
                            :editing="false"/>
              </div>
            </div>
            <div class="c-judge-editor__confirm-table-button">
              <button v-if="e_index === 0" v-on:click.prevent="change(g_index)"
                      class="c-button c-button--secondary"
                      :class="{'c-button--disabled': group.confirmed}">
                {{$t('panel.change')}}
              </button>
            </div>
          </div>
        </div>

        <div v-else :key="'r2-' + group.group">
          <!-- multiple rows: c-judge-editor__confirm-table -->
          <template v-if="group.multiple">
            <div class="c-judge-editor__confirm-table-multiple">
              <div class="c-judge-editor__confirm-table-label c-judge-editor__confirm-table-label-multiple">
                <span class="">{{$t('score.' + group.group)}}</span>
              </div>

              <div class="c-judge-editor__confirm-table-body c-judge-editor__confirm-table-body-multiple">
                <template v-for="(element) in group.elements">
                  <div class="c-judge-editor__confirm-table-value-label-pair"
                       v-for="(value, v_index) in getElementValues(element)"
                       :key="group.group + '-' + element.element + '-' + v_index">

                    <div class="c-judge-editor__confirm-table-label">
                      {{element.multiple ? (element.countLabels ? element.countLabels[value.properties.index]
                      : $t('score.'+element.element).substr(0,1).toUpperCase() + (value.properties.index+1))
                      : $t('score.'+element.element).substr(0,3).toUpperCase()}}
                    </div>

                    <div class="c-judge-editor__confirm-table-value">
                      <scoreValue :value="value" :editing="false"/>
                    </div>
                  </div>

                </template>
              </div>
              <div class="c-judge-editor__confirm-table-value-label-pair">
                <div class="c-judge-editor__confirm-value-label c-jugde-editor__confirm-total">
                  <span v-if="group.judgeTotal.enabled">TOT</span>
                </div>

                <scoreValue v-if="group.judgeTotal.enabled" :value="getJudgeTotalValue(group)" :editing="false"/>
              </div>


              <div class="c-judge-editor__confirm-table-button c-judge-editor__confirm-table-button-multiple">
                <button v-on:click.prevent="change(g_index)"
                        class="c-button c-button--secondary"
                        :class="{'c-button--disabled': group.confirmed}">
                  {{$t('panel.change')}}
                </button>
              </div>
            </div>

          </template>
          <!-- multiple rows: c-judge-editor__confirm-table -->

          <!-- gewone rows: c-judge-editor__confirm-table -->
          <template v-else>
            <div class="c-judge-editor__confirm-table">
              <div class="c-judge-editor__confirm-table-body">
                <div class="c-judge-editor__confirm-table-label">
                  <span class="">{{$t('score.' + group.group)}}</span>
                </div>

                <template v-for="element in group.elements">
                  <div v-for="(value, v_index) in getElementValues(element)" class="c-judge-editor__confirm-table-value"
                       :key="group.group + '-' + element.element + '-' + v_index">
                    <scoreValue :value="value" :editing="false"/>
                  </div>
                </template>

                <div v-if="group.judgeTotal.enabled">
                  <scoreValue v-if="group.judgeTotal.enabled" :value="getJudgeTotalValue(group)" :editing="false"/>
                </div>
              </div>

              <div class="c-judge-editor__confirm-table-button">
                <button v-on:click.prevent="change(g_index)"
                        class="c-button c-button--secondary"
                        :class="{'c-button--disabled': group.confirmed}">
                  {{$t('panel.change')}}
                </button>
              </div>
            </div>
          </template>
          <!-- /gewone rows: c-judge-editor__confirm-table -->
        </div>
      </template>
      <div v-if="trackRequirements" class="c-judge-editor__confirm-table u-margin-top-medium">
        <div class="c-judge-editor__confirm-table-label">
          {{$t('trackRequirements')}}
        </div>
        <div class="c-judge-editor__confirm-table-body">
          <strong>
            <span v-if="getRequirementsMet()">
              {{$t('requirementsMet')}}
            </span>
            <span v-else>
              {{$t('requirementsNotMet')}}
            </span>
          </strong>
        </div>
        <div class="c-judge-editor__confirm-table-button">
          <button v-on:click.prevent="toggleRequirements()"
                  class="c-button c-button--primary">
            {{$t('toggleRequirements')}}
          </button>
        </div>
      </div>
    </div>

    <div class="c-actions">
      <div class="c-actions__buttons">
      </div>

      <div class="c-actions__buttons">
        <button v-on:click.prevent="confirm()"
                class="c-button c-button--primary"
                :class="{'c-button--disabled': inputFinished}">
          {{$t('confirm')}}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
  import filter from 'lodash/filter'
  import participantLib from '@/lib/participant'
  import cloneDeep from 'lodash/cloneDeep'

  export default {
    name: "confirm-table",
    components: {
      'scoreValue': require('client/components/models/scoreValue.vue').default,
    },
    computed: {
      configuration: function () {
        return this.$store.state.exercise.exerciseConfiguration;
      },
      activePass: function () {
        return this.$route.params.pass;
      },
      exercise: function () {
        return this.$store.state.exercise.exercise;
      },
      chair: function () {
        return this.$store.state.exercise.chair;
      },
      pages: function () {
        return this.$store.state.exercise.pages
      },
      inputFinished: function () {
        return this.$store.state.exercise.inputFinished;
      },
      participation: function () {
        return participantLib.getParticipation(this.exercise.participationId)
      },
      trackRequirements: function () {
        const discipline = this.$store.state.eventDiscipline.discipline

        if (discipline.allowTrackSelection && this.$store.state.exercise.trackRequirements) {
          const category = participantLib.getCategory(this.participation)
          const catRound = category.rounds.find(r => r.roundIndex === this.exercise.roundIndex)
          console.log(catRound)
          return catRound.trackSelection.enable
            && catRound.trackSelection.requirementsCheck === this.exercise.exerciseTypeId
        }

        return false
      }
    },
    methods: {
      getJudgeTotalValue: function (group) {
        if (group.rosterLines === 'subElements') {
          return {
            properties: {
              leg: parseInt(this.activePass),
              group: group.group,
              element: group.element,
              index: 0,
              position: group.position,
            },

            valueType: group.judgeTotal.valueType
          }
        }

        return {
          properties: {
            leg: parseInt(this.activePass),
            group: group.group,
            element: 'total',
            index: -1,
            position: group.position,
          },

          valueType: group.judgeTotal.valueType
        }
      },
      getElementValues: function (element) {
        return filter(element.values, value => value.enabled)
      },

      getRequirementsMet: function () {
        const partRound = this.participation.rounds.find(r => r.roundIndex === this.exercise.roundIndex)

        return partRound.requirementsMet
      },

      toggleRequirements: function () {

        const data = {
          id: this.participation.id,
          rounds: cloneDeep(this.participation.rounds),
        }
        const partRound = data.rounds.find(r => r.roundIndex === this.exercise.roundIndex)
        partRound.requirementsMet = ! partRound.requirementsMet

        this.$store.dispatch('participation.save', { data })
        //this.$http.patch(config.root + '/participations/' + this.participation.id, this.participation)
      },

      change: function (page) {
        this.$store.dispatch("exercise.setPage", {page: page})
      },

      confirm: function () {
        this.$store.dispatch("exercise.confirmValues", {})
      },
    }
  };
</script>

<style scoped>
  .value-label {
    text-align: center;
  }
</style>
